import { Navigate, Route, Routes, } from "react-router-dom";
import Homepage from "../pages/Homepage";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Services from "../pages/Services";
import Reviews from "../pages/Reviews";
// import '../stylesheets/routeManager.css'

const RouteManager = ((): React.ReactElement => {
  return(
    <div id='app-body'>
      <Routes>
        <Route path={"/"} element={<Homepage />} />

        <Route path={"/about"} element={<About />} />

        <Route path={"/services"} element={<Services />} />

        <Route path={"/reviews"} element={<Reviews />}/>
        
        <Route path={"/contact"} element={<Contact />} />

        <Route path="*" element={<Navigate to='/' replace />} />
    
      </Routes>
    </div>
  )
})

export default RouteManager;