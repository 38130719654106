import { BrowserRouter as Router } from 'react-router-dom';
import './assets/stylesheets/app.scss';
import Navbar from "./components/navigation/Navbar";
import RouteManager from "./components/navigation/RouteManager";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <RouteManager />
      </Router>
    </div>
  );
}

export default App;
