import { ReactElement } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import "../../assets/stylesheets/reviews.scss"

const Reviews = ((): ReactElement => {
  return(
    <div id='reviews-container'>
      <h2>Testimonials</h2>
      <div id='reviews'>
        <div className='review'>
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <FontAwesomeIcon icon={faStar} />
          <p>Abbi has managed to put together a programme for me that suits my life commitments, exercise preferences and dietary requirements. It's only been a few weeks but I can already feel a difference in myself and I have felt motivated and encouraged through each step</p>
          <p className="client-name">- Abbie</p>
        </div>
      </div>
    </div>
  )
})

export default Reviews