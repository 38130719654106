import { Link } from "react-router-dom"

const NavOptions = ((props: {selected: string}) => {
  const {selected} = props
  return(
    <div className="nav-options">
      <Link 
        id="home" 
        to="/" 
        className={selected === 'home' ? 'selected' : ''}
      >
        Home
      </Link>

      <Link 
        id="about" 
        to="/about" 
        className={selected === 'about' ? 'selected' : ''}
      >
        About
      </Link>

      <Link 
        id="services" 
        to="/services" 
        className={selected === 'services' ? 'selected' : ''}
      >
        Services
      </Link>

      <Link 
        id="reviews" 
        to="/reviews" 
        className={selected === 'reviews' ? 'selected' : ''}
      >
        Reviews
      </Link>

      <Link 
        id="contact" 
        to="/contact" 
        className={selected === 'contact' ? 'selected' : ''}
      >
        Contact
      </Link>
    </div>
  )
})

export default NavOptions