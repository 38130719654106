import { ReactElement, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/stylesheets/navbar.scss";
import NavOptions from "./NavOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = ((): ReactElement => {
  const[selected, setSelected] = useState('home')
  const[burgerOpen, setBurgerOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const pathname = window.location.pathname
    setBurgerOpen(false)
    if(pathname === '/') { 
      setSelected('home') 
    } else {
      document.getElementById(pathname.substring(1)) ? 
        setSelected(pathname.substring(1)) : 
        setSelected('')
    }
  }, [location])

  return(
    <div id='header-bar'>
      <div id='mobile-menu' className={burgerOpen ? 'open' : 'closed'}>
        <button onClick={() => {setBurgerOpen(!burgerOpen)}}>
          <FontAwesomeIcon icon={faBars} size='xl'/>
        </button>
        <NavOptions selected={selected} />
      </div>
      <div id='web-menu'>
        <NavOptions selected={selected} />
      </div>
      <div id='title'>
        <Link id="name-link" to="/">AB Personal Training</Link>
      </div>
      <div className='title-spacer' />
    </div>
  )
});

export default Navbar;