import { ReactElement } from "react"
import "../../assets/stylesheets/services.scss"


const Services = ((): ReactElement => {
  return (
    <div id="services-container">
      {/* <h1>Services</h1> */}
      <div id="services-list">
        <div className="service">
          <div id="nutrition-only" className="image"/>
          <h3>Nutrition only</h3>
          <p>
            Wanting help with your diet? 
            I can help to highlight areas of improvement for hormones, suggest tips for pregnancy, or give you macros to match your goals. 
          </p>
          <a href="/contact" className="book-button">
            Book now
          </a>
        </div>
        <div className="service">
          <div id="online-fitness" className="image"/>
          <h3>Online fitness</h3>
          <p>
            I can give you a fitness program tailored to your specific needs in this option. 
            This includes: pregnancy & post-partum, weight loss, general health & wellness, and more. 
            The length of program is variable depending on your goals and situation.
          </p>
          <a href="/contact" className="book-button">
            Book now
          </a>
        </div>
        <div className="service">
          <div id="fitness-and-nutrition" className="image"/>
          <h3>Online fitness and nutrition</h3>
          <p>
            In this option, I will give you a full, fully personalised fitness program and nutrition support. 
            This is completely unique to you and is the option that will see best results.
          </p>
          <a href="/contact" className="book-button">
            Book now
          </a>
        </div>
      </div>
    </div>
  )
})

export default Services