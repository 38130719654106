import { ReactElement } from "react"
import "../../assets/stylesheets/contact.scss"

const Contact = ((): ReactElement => {
  return(
    <div id="contact-container">
      <div id="contact-picture"/>
      <div id="contact-links">
        <h2>Message me on Instagram</h2>
        <a href="https://www.instagram.com/abpersonaltrainingessex" target="_blank" rel="noreferrer">@abpersonaltrainingessex</a>
        <br />
        <br />
        <h2>Send me an email</h2>
        <a href="mailto:abpersonaltrainingessex@gmail.com" target="_blank" rel="noreferrer">abpersonaltrainingessex@gmail.com</a>
      </div>
    </div>
  )
})

export default Contact