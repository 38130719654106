import { ReactElement } from "react"
import "../../assets/stylesheets/about.scss"

const About = ((): ReactElement => {
  return(
    <div id="about-container">
      <h1>Hi, I'm Abbi</h1>
      <div id="about-text-container">
        <p>I discovered weight training around ten years ago. Originally, I used it as a way to train for an expedition I was doing at the time to Greenland, but following that trip, I fell in love with the way the gym made me feel. I love feeling strong and capable, pushing my limits and achieving my goals. Strength training forces me to focus on that moment, making it perfect for maintaining my mental health. Since taking up strength training, I also started to incorporate yoga as a way to stretch, strengthen my core, and focus on my breathing and mindfulness.</p>
        <p>This journey led me to wanting to know more about training overall, and so I completed my Level 3 Personal Training in 2022, and used it to further my own training. After being diagnosed with PCOS, I was told I would struggle to get pregnant, and so dove deep into how I could balance my hormones using food and exercise. I reduced my high intensity training, focussed on low-intensity steady state cardio, such as walking, and did at least one yoga class a week to balance my mindset. I managed to fall pregnant with my son, and gave birth in May 2024. Now, I’d like to support people to do the exercise they want to do, especially mums and mums-to-be.</p>
      </div>
      <div id="greenland-image"/>
    </div>
  )
})

export default About