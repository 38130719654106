import { ReactElement } from "react"
import "../../assets/stylesheets/homepage.scss"

const Homepage = ((): ReactElement => {
  return (
    <div id="home-elements">
      <div id="home-image-container">
        <div id="home-image"/>
      </div>
      <div id="home-text">
        <h1 className="name">AB Personal Training</h1>
        <p>Level 3 Personal Training</p>
        <p>Level 3 Nutrition</p>
        <p>Level 3 Pre and Postnatal Exercise and Nutrition</p>
        <a href="/services" id = "services-button">
          Learn more
        </a>
      </div>
    </div>
  )
})

export default Homepage